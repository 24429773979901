import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import './../assets/sass/fontawesome-all.min.css';
function About() {
  return (
    <StaticQuery
      query={graphql`
        {
          mongodbCloserDevCmsPages(siteId: { eq: "5dac524cf761b3073f3ee47f" }) {
            content {
              about {
                advantagesTitle
                advantagesText
                advantages {
                  text
                  icon
                }
                text
                title
                departments {
                  description
                  imageUrl
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => getContent(data.mongodbCloserDevCmsPages.content)}
    />
  );
}

function getContent(content) {
  // console.log('about');
  // console.log(content);
  return (
    <div id={'about'} className='inner'>
      <h2 className='major'>{content.about.title}</h2>
      <p>{content.about.text}</p>
      <br></br>
      <br></br>
      <h2 className='major'>{content.about.advantagesTitle}</h2>
      <p>{content.about.advantagesText}</p>
      <ul className='advantages-list'>
        {content.about.advantages.map((element) => (
          <li>
            <span className='fa-stack'>
              <span className='hexagon'>
                <i className={element.icon}></i>
              </span>
            </span>
            <p>{element.text}</p>
          </li>
        ))}
      </ul>
      <br></br>
      <br></br>

      {/* <h2 className="major">Os nossos departamentos</h2>

        <section className="features">
          {getAllDepartments(content.about.departments)}
        </section> */}
    </div>
  );
}

export default About;
