import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

function ProductsList() {
  const {
    fruitExposition: {
      content: {
        title: fruitTitle,
        products: [
          {
            pictures_Alt: fruit_pictures_Alt,
            imgs: [fruitSrc],
          },
        ],
      },
    },
    breadExposition: {
      content: {
        title: breadTitle,
        products: [
          {
            pictures_Alt: bread_pictures_Alt,
            imgs: [breadSrc],
          },
        ],
      },
    },
    otherProducts: {
      content: {
        title: otherTitle,
        products: [
          {
            pictures_Alt: other_pictures_Alt,
            imgs: [otherSrc],
          },
        ],
      },
    },
    antiTheft: {
      content: {
        title: antiTheftTitle,
        products: [
          {
            pictures_Alt: anti_theft_pictures_Alt,
            imgs: [antiTheftSrc],
          },
        ],
      },
    },
  } = useStaticQuery(pageQuery);
  return (
    <section id='products' className='wrapper spotlight style mt-3'>
      <div className='inner w-100'>
        <h2 className='major w-100' style={{ marginTop: '150px' }}>
          Categorias de Produtos
        </h2>
      </div>
      <div className='inner mt-3'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
          className='row'
        >
          <div
            className='col-3 col-12-medium'
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <a
              target='_blank'
              style={{ cursor: 'pointer', border: 0,width: '250px', }}
              href='/exposicao-legumes-fruta'
            >
              {/* <img
                loading='lazy'
                style={{
                  width: '275px',
                  height: '185px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                  backgroundColor: '#5db9841f',
                }}
                alt={fruit_pictures_Alt}
                src={fruitSrc}
              /> */}
               <i
              style={{
                width: '250px',
                height: '135px',
                objectFit: 'contain',
                borderRadius: '12px',
                backgroundColor: '#5db9841f',
                fontSize: '5rem',
                textAlign: 'center',
                padding: '30px'
              }}
              className={'fas fa-apple-alt'}></i>
              <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{fruitTitle}</h4>
            </a>
          </div>
          <div
            className='col-3 col-12-medium'
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <a
              target='_blank'
              style={{ cursor: 'pointer', border: 0,width: '250px', }}
              href='/exposicao-padaria-pasteleria'
            >
              {/* <img
                loading='lazy'

                alt={bread_pictures_Alt}
                src={breadSrc}
              /> */}
              <i
              style={{
                width: '250px',
                height: '135px',
                objectFit: 'contain',
                borderRadius: '12px',
                backgroundColor: '#5db9841f',
                fontSize: '5rem',
                textAlign: 'center',
                padding: '30px'
              }}
              className={'fas fa-bread-slice'}></i>
              <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{breadTitle}</h4>
            </a>
          </div>
          <div
            className='col-3 col-12-medium'
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <a
              target='_blank'
              style={{ cursor: 'pointer', border: 0,width: '250px', }}
              href='/produtos-diversos-complementares'
            >
              {/* <img
                loading='lazy'
                style={{
                  width: '275px',
                  height: '185px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                  backgroundColor: '#5db9841f',
                }}
                alt={other_pictures_Alt}
                src={otherSrc}
              /> */}
               <i
              style={{
                width: '250px',
                height: '135px',
                objectFit: 'contain',
                borderRadius: '12px',
                backgroundColor: '#5db9841f',
                fontSize: '5rem',
                textAlign: 'center',
                padding: '30px'
              }}
              className={'fas fa-archive'}></i>
              <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{otherTitle}</h4>
            </a>
          </div>
          <div
            className='col-3 col-12-medium'
            style={{
              marginBottom: '150px',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <a
              target='_blank'
              style={{ cursor: 'pointer', border: 0,width: '250px', }}
              href='/antifurto'
            >
              {/* <img
                loading='lazy'
                style={{
                  width: '275px',
                  height: '185px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                  backgroundColor: '#5db9841f',
                }}
                alt={anti_theft_pictures_Alt}
                src={antiTheftSrc}
              /> */}
              <i
              style={{
                width: '250px',
                height: '135px',
                objectFit: 'contain',
                borderRadius: '12px',
                backgroundColor: '#5db9841f',
                fontSize: '5rem',
                textAlign: 'center',
                padding: '30px'
              }}
              className={'fas fa-user-shield'}></i>
              <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{antiTheftTitle}</h4>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export const pageQuery = graphql`
  query {
    fruitExposition: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "61686431a9a2e2094a6950ad" }
    ) {
      content {
        title
        description
        products {
          pictures_Alt
          imgs
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
    breadExposition: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "616db6ca30ccf05ceef92846" }
    ) {
      content {
        title
        description
        products {
          pictures_Alt
          imgs
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
    otherProducts: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "616db787b9266c8c686e9d67" }
    ) {
      content {
        title
        description
        products {
          pictures_Alt
          imgs
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
    antiTheft: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "616db800d065c078e1dc44b5" }
    ) {
      content {
        title
        description
        products {
          pictures_Alt
          imgs
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
  }
`;

export default ProductsList;
