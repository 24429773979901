import React from "react";

import Header from "../components/Header";
import Layout from "../components/Layout";
import About from "../components/About";
import News from "../components/News";
import ProductsList from "../components/Products-List";
// import loadable from '@loadable/component';

// const News = loadable(() => import('../components/News'))
// const Product = loadable(() => import('../components/Product'))

const IndexPage = () => (
  <Layout>
    <Header></Header>

    <section id="wrapper">
      <section id="four" className="wrapper alt style1 advantagesSection">

        <About></About>
          {/* <hr></hr> */}

          {/*< ul className="actions">
            <li>
              <a href="/#" className="button">
                Browse All
              </a>
            </li>
          </ul> */}

      </section>
      <ProductsList></ProductsList>
      <News></News>

    </section>
  </Layout>
);

export default IndexPage;
